// import 'react-app-polyfill/ie11';
// import { loadableReady } from '@loadable/component'
import './polyfills'

// import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
// import { hydrate, render } from "react-dom";
// import './index.css'
import { BrowserRouter } from 'react-router-dom'
import './Config/i18n'

import * as serviceWorker from './serviceWorker'
import { history } from './Config/sentryInit'
import App from './App'

if (process.env.NODE_ENV === 'development') {
    var axe = require('react-axe')
    axe(React, ReactDOM, 1000)
    // const a11y = require('react-a11y').default
    // a11y(React, ReactDOM, {
    //   rules: {
    //     'img-uses-alt': 'warn',
    //     'img-redundant-alt': ['warn', ['image', 'photo', 'foto', 'bild']],
    //     // ...
    //   },
    // })
}

// ReactDOM.render(
//     <App />
// , document.getElementById('root'))

// const theme = createMuiTheme({typography: {useNextVariants: true,}});

// const generateClassName = createGenerateClassName();

// if (process.env.NODE_ENV === 'development') {
// if(!isMobileOnly) {
//   import(
//     /* webpackChunkName: "ipad_keyboard" */
//     `./Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/ipade/keyboard.min.css`)
// }
// else {
//   import(
//     /* webpackChunkName: "mobile_keyboard" */
//     `./Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/mobilee/keyboard.min.css`)
// }
// }

document.addEventListener('DOMContentLoaded', event => {
    const rootElement = document.getElementById('root')
    const placeholder = document.getElementById('placeholder')
    // loadableReady(() => {

    // ReactDOM.unstable_createRoot(rootElement, { hydrate: true }).render(<ClientAppRouter />);

    if (!placeholder) {
        ReactDOM.unstable_createRoot(rootElement, { hydrate: true }).render(
            <BrowserRouter history={history}>
                <App />
            </BrowserRouter>
        )
        // ReactDOM.hydrate(
        //     <BrowserRouter>
        //         <ClientApp isPhone={isMobileOnly} />
        //     </BrowserRouter>,
        //     rootElement
        // )
    } else {
        //unstable_createRoot
        ReactDOM.unstable_createRoot(rootElement, { hydrate: false }).render(
            <BrowserRouter history={history}>
                <App />
            </BrowserRouter>
        )
        // ReactDOM.render(
        //     <BrowserRouter>
        //         <ClientApp isPhone={isMobileOnly} />
        //     </BrowserRouter>,
        //     rootElement
        // )
    }

    // })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
