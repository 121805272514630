// import { useTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import MenuIcon from '@material-ui/icons/Menu'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { keyboardLanguages, sitePath } from '../StaticData/SiteData'
import '../Styles/shared.scss'
import { AddThisShareMenuButton } from './AddThis'
import './DenseAppBar.scss'
import DropButtonMenu from './DropButtonMenu'
import LogoBreadcrumbs from './DenseAppBar/LogoBreadcrumbs'
// var classes = new BEMHelper('DenseAppBar')
// const cn = withNaming({ n: '', e: '__', m: '--' });
// const bem = cn('DenseAppBar');

/// Uservoice global

// end Uservoice global

import KeyboardIcon from '@material-ui/icons/Keyboard'
import { LazyMenu } from './LazyLoad'

import { flags } from './Footer'

const DenseAppBar = ({ t, i18n, sectionKey, kbLangKey, history, changeLanguage }) => {
    // const [t, i18n] = useTranslation(['UI'], { i18next })

    useEffect(() => {
        const feedbackLang = i18n.language ? i18n.language : 'en'
        window.UserVoice = window.UserVoice || []
        window.UserVoice.push(['set', 'locale', feedbackLang])
        window.UserVoice.push([
            'identify',
            {
                language: feedbackLang
            }
        ])
        // window.UserVoice.push(['addTrigger', '#contact_a', { mode: 'contact' }]);
        window.UserVoice.push(['autoprompt', {}])
    }, [i18n.language])

    useEffect(() => {
        const feedbackLang = i18n.language ? i18n.language : 'en'
        window.UserVoice.push(['set', 'locale', feedbackLang])
        window.UserVoice.push([
            'identify',
            {
                language: feedbackLang
            }
        ])
    }, [i18n.language])

    const handleFeedbackClick = () =>
        import('scriptjs').then(scriptjs =>
            scriptjs.default('//widget.uservoice.com/tMhknfhY1IuA7rrlVQ8g.js', () => {
                window.UserVoice.push([
                    'set',
                    {
                        accent_color: '#6aba2e',
                        trigger_color: 'white',
                        trigger_background_color: '#6aba2e',
                        position: i18n.dir() === 'rtl' ? 'top-left' : 'top-right'
                    }
                ])
                window.UserVoice.show()
            })
        )

    const menuOptions = [
        // <NavLink
        //     key='1'
        //     className='Button NavLink Section'
        //     to={sitePath.keysToPath(kbLangKey, 'freetext', i18n, locale)}
        //     isActive={() => sectionKey === 'freetext'}
        //     activeClassName='selected'
        // >
        //     {t('UI:(section)Keyboard')}
        // </NavLink>,

        // <Button
        //     key='2'
        //     color='inherit'
        //     className={
        //         'Button DropMenu Section' + (sectionKey === 'google' || sectionKey === 'wiki' ? ' selected' : '')
        //     }
        //     buttonRef={node => {
        //         searchMenuAnchorEl.current = node
        //     }}
        //     aria-owns={searchOpen ? 'menu-list-grow' : undefined}
        //     aria-haspopup='true'
        //     onClick={handleToggle}
        // >
        //     {t('UI:(section)Search')}
        //     <ArrowDropDownIcon />
        // </Button>,

        // <NavLink
        //     key='3'
        //     className='Button NavLink Section'
        //     to={sitePath.keysToPath(kbLangKey, 'email', i18n, locale)}
        //     activeClassName='selected'
        // >
        //     {t('UI:(section)Send Email')}
        // </NavLink>,
        // <NavLink
        //     key='4'
        //     className='Button NavLink Section'
        //     to={sitePath.keysToPath(kbLangKey, 'trans', i18n, locale)}
        //     activeClassName='selected'
        // >
        //     {t('UI:(section)Translation')}
        // </NavLink>,
        // <NavLink
        //     // className='Back g2hlogo-up'
        //     className='Button NavLink Section'
        //     to={sitePath.keysToPath('', '', i18n)}
        //     aria-label={t('UI:Change keyboard language')}
        // >
        //     {/* <ArrowBackIcon /> */}
        //     {t('UI:Change keyboard language')}
        // </NavLink>,
        kbLangKey ? (
            <DropButtonMenu
                key='5'
                text={keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n)}
                variant='text'
                textTransform={'none'}
                // bgColor='#3480fc'
                Icon={KeyboardIcon}
                resetKey={kbLangKey + sectionKey}
                eventKey='appbar_kblang'
                color='#bfbfbf'
                which={'change_language'}
                menuItemProps={{ history }}
            />
        ) : (
            undefined
        ),
        <DropButtonMenu
            key='6'
            text={keyboardLanguages.data[i18n.language].name_native}
            resetKey={i18n.language}
            textTransform={'none'}
            variant='text'
            // bgColor='#3480fc'
            Icon={() => (
                <img alt={keyboardLanguages.data[i18n.language].name_native} width='20' src={flags[i18n.language]} />
            )}
            eventKey='appbar_locale'
            color='#bfbfbf'
            which={'site_language'}
            onItemClick={locale => changeLanguage(locale)}
        />,

        <AddThisShareMenuButton key='7'>{t('UI:(section)Share')}</AddThisShareMenuButton>,
        <Button key='8' color='inherit' className='Button' onClick={handleFeedbackClick}>
            {t('UI:Feedback')}
        </Button>
    ]

    return (
        <>
            <div className='DenseAppBar'>
                <AppBar position='static' className='AppBar' role='navigation'>
                    <Toolbar variant='dense' style={{ display: '-ms-flexbox' }}>
                        {kbLangKey && (
                            <LogoBreadcrumbs
                                t={t}
                                i18n={i18n}
                                kbLangKey={kbLangKey}
                                sectionKey={sectionKey}
                                history={history}
                            />
                            // <Link
                            //     className='Back g2hlogo-up'
                            //     to={sitePath.keysToPath('', '', i18n)}
                            //     aria-label={t('UI:Change keyboard language')}
                            // >
                            //     <ArrowBackIcon />
                            //     {t('UI:Change keyboard language')}
                            // </Link>
                        )}
                        {/* {kbLangKey && (
                            <Link
                                className='Logo g2hlogo-down'
                                to={sitePath.keysToPath('', '', i18n)}
                                aria-label={t('UI:Change keyboard language')}
                            >
                                <div className='site_name'>
                                    Gate2Home <div className='logo-bubble' alt='Logo Symbol' width='38' height='37' />
                                </div>
                            </Link>
                        )} */}

                        {!kbLangKey && (
                            <Link
                                className='UpperLeftLogo'
                                to={sitePath.keysToPath('', '', i18n)}
                                aria-label={t('UI:Change keyboard language')}
                            >
                                Welcome to Gate2Home.com
                            </Link>
                        )}

                        <div style={{ flexGrow: 1 }} />

                        <div className='LargeMenu'>{menuOptions}</div>

                        <div className='CollapsedMenu'>
                            <Link
                                className='site_name'
                                to={sitePath.keysToPath(kbLangKey, '', i18n)}
                                aria-label={t('UI:Change keyboard language')}
                            >
                                {/* {kbLangKey && (
                                    <span className='site_name-lang'>
                                        {keyboardLanguages.data[kbLangKey].name_native}
                                    </span>
                                )} */}
                            </Link>
                            <LazyMenu
                                id={'MobileMenu'}
                                reactLazyFunc={() => import('./DenseAppBar/MobileMenu')}
                                ButtonA={({ onClick }) => (
                                    <IconButton
                                        className='mobileMenuButton'
                                        color='inherit'
                                        aria-label='Menu'
                                        onClick={onClick}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                menuOptions={
                                    kbLangKey ? menuOptions : menuOptions.slice(Math.max(menuOptions.length - 2, 1))
                                }
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        </>
    )
}

export default DenseAppBar
