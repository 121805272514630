// "use strict";
import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import Locales from '../StaticData/Locales'
import { local as storeLocal } from './storage'

const lngDetector = new LanguageDetector()

lngDetector.addDetector(Locales.pathLanguageDetector)

const LocizeOptions = {
    react: {
        useSuspense: true
    },
    fallbackLng: 'en',
    keySeparator: '.',
    preload: false, // (needed just on prerendeing and scripts)
    detection: {
        // order and from where user language should be detected
        order: ['g2hPathDetector', 'path', 'cookie', 'localStorage', 'session'], //  'path','session', 'querystring', 'header'

        // keys or params to lookup language from
        // lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSession: 'lng',
        lookupPath: 'lng',
        lookupFromPathIndex: 0,

        // cache user language
        caches: ['localStorage', 'cookie'], // false

        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain'
        // cookieExpirationDate: new Date(),
        // cookieDomain: 'myDomain',
        // cookieSecure: true // if need secure cookie
    },
    debug: false, // || NODE_SCRIPT,
    ns: ['LANG', 'UI', 'EMAIL', 'SEO', 'BRAND'],
    saveMissing: false, // if dev
    saveMissingTo: 'all',
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
        referenceLng: 'en'
    }
    // react: {
    //   wait: true
    // }
}

if (process.env.NODE_ENV === 'development') {
    // const locizeEditor = require('locize-editor')
    const Backend = require('i18next-chained-backend').default
    const LocalStorageBackend = require('i18next-localstorage-backend').default
    const LocizeBackend = require('i18next-locize-backend').default

    LocizeOptions.preload = Locales.data
    LocizeOptions.detection.caches = false
    LocizeOptions.debug = true
    LocizeOptions.saveMissing = true

    const LocizeEditorOptions = {
        onEditorSaved: function(lng, ns) {
            i18n.reloadResources(lng, ns, () => {
                console.log(`LocizeEditor updated text in ${lng}:${ns}`)
                i18n.changeLanguage(i18n.language) // => refresh i18n => refresh all depanded components (ilan)
            })
        }
    }

    i18n.use(initReactI18next)
        .use(Backend)
        // .use(locizeEditor)

        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        // .use(Locize)
        // // // detect user language
        // // // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(lngDetector)

        // pass the i18n instance to react-i18next.
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            ...LocizeOptions,
            editor: {
                //
                ...LocizeEditorOptions
            },
            backend: {
                ...LocizeOptions.backend,
                backends: [
                    LocalStorageBackend,
                    LocizeBackend
                    // locizeEditor,
                    // lngDetector
                ],
                backendOptions: [
                    {
                        // prefix: 'i18next_res_',
                        expirationTime: 60 * 60 * 1000, // 1 hour
                        store: storeLocal
                    },
                    LocizeOptions.backend
                ]
            }
        })
}

if (process.env.NODE_ENV === 'production') {
    // const loadLocales = (url, options, callback, data) => {
    //     try {
    //         import('../../public/locales/' + url + '.json')
    //             .then(locale => callback(locale, { status: '200' }))
    //             .catch(e => console.log(e))
    //     } catch (e) {
    //         callback(null, { status: '404' })
    //     }
    // }

    //// removing LocalStorageBackend from production (checking if impacts performance)
    // const backends = typeof window !== 'undefined' ? [LocalStorageBackend, XHR] : [XHR]
    // const backends = [XHR]

    // const backendOptionsAll = [
    //     {
    //         // prefix: 'i18next_res_',
    //         expirationTime: 60 * 60 * 1000 // 1 hour
    //         // store: storeLocal
    //     }
    // ]
    // const backendOptions = [backendOptionsAll[0]] //typeof window !== 'undefined' ? backendOptionsAll : [backendOptionsAll[1]]

    i18n.use(initReactI18next)
        .use(XHR)
        .use(lngDetector)

        .init({
            ...LocizeOptions
        })
}

// }

export default i18n
