import React, { useCallback, useReducer } from 'react'
import { withLog } from './withLog'
import { local as storeLocal } from '../Config/storage'

const initialState = {
    active: true,
    showGoogleResults: false,
    showWikipediaResults: false,
    showEnglishKeyboard: false,
    // mobileLoadKeyboard: false,
    focusInputId: false,
    prevFocusInputId: false,
    showKeyboard: true,
    layout: ''
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'save-storage':
            state[payload.key] !== undefined && storeLocal.setItem(`g2h_${payload.key}`, state[payload.key] || '')
            return state
        case 'retreive-storage':
            return { ...state, [payload.key]: storeLocal.getItem(`g2h_${payload.key}`) || '' }
        case 'update-value':
            return { ...state, [payload.key]: payload.value || '' }
        case 'set-active':
            return { ...state, active: payload }
        case 'show-google-results':
            return { ...state, showGoogleResults: payload }
        case 'show-wikipedia-results':
            return { ...state, showWikipediaResults: payload }
        case 'show-keyboard':
            return { ...state, showKeyboard: payload }
        // case 'mobile-load-keyboard':
        //   return {...state, mobileLoadKeyboard: true}
        case 'english-keyboard':
            return payload === state.showEnglishKeyboard ? state : { ...state, showEnglishKeyboard: payload }
        case 'focus-input':
            return { ...state, prevFocusInputId: state.focusInputId, focusInputId: payload }
        default:
            return state
    }
}

const State = React.createContext()
State.displayName = 'KeyboardState'
const Dispatch = React.createContext()
Dispatch.displayName = 'KeyboardDispatch'

const combinedReducer = withLog(reducer, 'Keyboard')

const Provider = ({ children }) => {
    const memoizedReducer = useCallback(combinedReducer, [])
    const [state, dispatch] = useReducer(memoizedReducer, initialState)

    return (
        <Dispatch.Provider value={dispatch}>
            <State.Provider value={state}>{children}</State.Provider>
        </Dispatch.Provider>
    )
}

export default { State, Dispatch, Provider }
