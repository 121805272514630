import React from 'react'
// import logo from './Loader/logo.svg'
// import { makeStyles } from '@material-ui/core/styles'
// import LinearProgress from '@material-ui/core/LinearProgress'

import './Loader.scss'

// const useStyles = makeStyles({
//     root: {
//         flex-grow: 1;
//         position: absolute;
//         width: 100%;
//         textAlign: 'center'
//     }
// })

// loading component for suspence fallback
const Loader = ({ style }) => {
    // const classes = useStyles()
    return (
        <div
            className='lds-container'
            style={style ? style : undefined}
            // className={classes.root}
        >
            {/* <LinearProgress /> */}
            <div className='lds-ellipsis'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
