import React, { useRef } from 'react'

// import {isMobileOnly} from 'react-device-detect';

import PersistentContentEditable from '../../../Components/PersistentContentEditable'

import { keyboardLanguages } from '../../../StaticData/SiteData.js'

const FreeText = React.memo(({ kbLangKey, sectionKey, i18n }) => {
    const textbox1 = useRef(null)

    return (
        <div id='freetext-sect'>
            <div className='freetextdiv'>
                <PersistentContentEditable
                    typeHereString={i18n.t('UI:Type here')}
                    focus={true}
                    className='defaultvkinput vkinput freetextarea textbox1_textarea query_text'
                    adaptiveFontSize={true}
                    innerRef={textbox1}
                    id='textbox1'
                    dataSaveId='textbox1'
                    name='q'
                    readOnly='readonly'
                    role='textbox'
                    showEditBar={true}
                    rtl={keyboardLanguages.data[kbLangKey].rtl}
                    style={{
                        direction: keyboardLanguages.data[kbLangKey].rtl ? 'rtl' : false,
                        textAlign: keyboardLanguages.data[kbLangKey].rtl ? 'right' : false
                    }}
                    tabIndex='0'
                />
            </div>
        </div>
    )
})

export default FreeText
