import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { browserVersion, isIE, isIOS, isMobileOnly } from 'react-device-detect'
import { escapedToHtml } from '../aux.js'
import Gate2HomeContext from '../Context/Gate2HomeContext'
import KeyboardContext from '../Context/KeyboardContext'
import TransliterationContext from '../Context/TransliterationContext'
import EditBar from './EditBar/EditBar.js'
// const EditBar = React.lazy(() => import('./EditBar/EditBar.js'))

const PersistentContentEditable = React.memo(
    ({
        dataSaveId,
        onFocus,
        onChange,
        onClick,
        onBlur,
        onKeyDown,
        className,
        typeHereString = '',
        innerRef,
        focus = false,
        adaptiveFontSize = false,
        englishOnly = false,
        showEditBar = false,
        rtl = false,
        ...props
    }) => {
        const keyboardState = useContext(KeyboardContext.State)
        const keyboardDispatch = useContext(KeyboardContext.Dispatch)

        const Gate2HomeState = useContext(Gate2HomeContext.State)
        // const Gate2HomeDispatch = useContext(Gate2HomeContext.Dispatch)

        const transliterationState = useContext(TransliterationContext.State)
        const transliterationDispatch = useContext(TransliterationContext.Dispatch)

        const [fontSizeClass, setFontSizeClass] = useState('len0')

        const ghostInput = useRef(null)
        const calcCaretRef = useRef(null)
        const caretRef = useRef(null)

        // const showEnglishKeyboard = flag => {
        //   keyboardState.showEnglishKeyboard !== flag && keyboardDispatch({ type: "show-english-keyboard", payload: flag})
        // }

        useEffect(() => {
            focus && innerRef.current.focus()
        }, [focus, innerRef])

        const updateTransliterationBoxPosition = useCallback(() => {
            if (innerRef && innerRef.current && !isMobileOnly) {
                import('caret-pos').then(({ position }) => {
                    try {
                        const pos = position(innerRef.current)
                        pos.top += innerRef.current.getBoundingClientRect().top
                        pos.left += innerRef.current.getBoundingClientRect().left
                        transliterationState.transliteration &&
                            transliterationDispatch({ type: 'update-return-position', payload: pos })
                        delete pos.height
                    } catch (e) {
                        // error, skip it
                    }
                })
            }

            if (isMobileOnly) {
                transliterationDispatch({
                    type: 'update-return-position',
                    payload: {
                        top: Math.ceil(document.querySelector('#virtualKeyboard').getBoundingClientRect().y - 3),
                        left: 'auto'
                    }
                })
            }
        }, [innerRef, transliterationDispatch, transliterationState.transliteration])

        useEffect(() => {
            isMobileOnly && transliterationState.transliteration && updateTransliterationBoxPosition()
        }, [transliterationState.transliteration, updateTransliterationBoxPosition])

        useEffect(() => {
            caretRef && caretRef.current && (caretRef.current.style.display = keyboardState.active ? 'block' : 'none')
        }, [keyboardState.active])

        const insertCaretAtCursor = useCallback(() => {
            if (keyboardState.active) {
                const elm = innerRef.current
                const caret = caretRef.current
                const calc_caret = calcCaretRef.current

                const s = window.getSelection()
                const r = document.createRange()
                // for weird no newline if no element at the end

                if (s.anchorOffset === s.focusOffset) {
                    if (s.anchorNode) {
                        // soultion for IE and safari mobile
                        r.setStart(s.anchorNode, s.anchorOffset)
                        r.setEnd(s.focusNode, s.focusOffset)
                    } else {
                        r.setStart(elm, s.anchorOffset)
                        r.setEnd(elm, s.focusOffset)
                    }

                    r.deleteContents()

                    r.insertNode(calc_caret)

                    // The char that makes the caret not break number sequances or arabic connected chars:
                    // https://en.wikipedia.org/wiki/Zero-width_joiner
                    const zwj = document.createTextNode('\u200D')
                    r.insertNode(zwj)

                    // addClass(caret,"text_caret_mobile")
                    const caret_height = parseInt(window.getComputedStyle(elm).lineHeight, 10)
                    caret.style.height = caret_height + 'px'
                    caret.style.left = calc_caret.offsetLeft + 'px'
                    caret.style.right = calc_caret.offsetLeft + 'px'
                    caret.style.top = calc_caret.offsetTop + 'px'

                    zwj.remove()
                    document.body.appendChild(calc_caret)
                    elm.normalize()
                    // elm.flag = true;
                }
            }
        }, [keyboardState.active, innerRef])

        const saveValueToLocalStorage = useCallback(() => {
            if (dataSaveId) {
                keyboardDispatch({
                    type: 'save-storage',
                    payload: { key: dataSaveId }
                })
            }
        }, [dataSaveId, keyboardDispatch])

        useEffect(() => {
            keyboardDispatch({
                type: 'retreive-storage',
                payload: { key: dataSaveId }
            })
            if (isMobileOnly && !isIOS) {
                insertCaretAtCursor()
            }

            const timeout = setTimeout(() => {
                saveValueToLocalStorage()
            }, 60000)

            window.addEventListener('beforeunload', saveValueToLocalStorage)
            return () => {
                clearTimeout(timeout)
                window.removeEventListener('beforeunload', saveValueToLocalStorage)
                saveValueToLocalStorage()
            }
        }, [dataSaveId, keyboardDispatch, insertCaretAtCursor, saveValueToLocalStorage])

        useEffect(() => {
            const query = Gate2HomeState.parsedHash && (Gate2HomeState.parsedHash.q || Gate2HomeState.parsedHash.t)
            if (query) {
                keyboardDispatch({
                    type: 'update-value',
                    payload: { key: dataSaveId, value: escapedToHtml(query) }
                })
            }
        }, [Gate2HomeState.parsedHash, dataSaveId, keyboardDispatch])

        // const insertTextAtCursor = text => {
        //     let elm = innerRef.current

        //     let s = window.getSelection()
        //     let r = document.createRange()
        //     //for weird no newline if no element at the end

        //     if (s.anchorOffset === s.focusOffset) {
        //         if (s.anchorNode) {
        //             //soultion for IE and safari mobile
        //             r.setStart(s.anchorNode, s.anchorOffset)
        //             r.setEnd(s.focusNode, s.focusOffset)
        //         } else {
        //             r.setStart(elm, s.anchorOffset)
        //             r.setEnd(elm, s.focusOffset)
        //         }

        //         r.deleteContents()

        //         r.insertNode(document.createTextNode(text))

        //         elm.normalize()
        //         // elm.flag = true;
        //     }
        // }

        // useEffect(() => {
        //     if (
        //         transliterationState.transliteration &&
        //         transliterationState.textToInsert &&
        //         innerRef.current.id == keyboardState['prevFocusInputId']
        //     ) {
        //         insertTextAtCursor(transliterationState.textToInsert)
        //     }
        // }, [transliterationState.textToInsert])

        const handleChange = useCallback(
            evt => {
                if (keyboardState.active) {
                    onChange && onChange()

                    if (adaptiveFontSize && evt.target.value) {
                        const len = evt.target.value.length
                        setFontSizeClass(len > 85 ? (len > 170 ? 'len170' : 'len85') : 'len0')
                    }

                    isMobileOnly && !isIOS && insertCaretAtCursor()

                    // setText(evt.target.value)
                    keyboardDispatch({ type: 'update-value', payload: { key: dataSaveId, value: evt.target.value } })

                    isMobileOnly && transliterationState.transliteration && updateTransliterationBoxPosition()
                }
            },
            [
                adaptiveFontSize,
                dataSaveId,
                insertCaretAtCursor,
                keyboardDispatch,
                keyboardState.active,
                onChange,
                transliterationState.transliteration,
                updateTransliterationBoxPosition
            ]
        )

        const handleTextAreaBlurIos = useCallback(
            e => {
                keyboardState.active && innerRef.current.setAttribute('readonly', true)
                !keyboardState.active && innerRef.current.removeAttribute('readonly')
                onBlur && onBlur()
            },
            [innerRef, keyboardState.active, onBlur]
        )

        const handleFocus = useCallback(
            e => {
                if (keyboardState.active) {
                    onFocus && onFocus()
                    // if(englishOnly) showEnglishKeyboard(true);
                    keyboardDispatch({ type: 'focus-input', payload: e.target.id })
                    saveValueToLocalStorage()
                    isMobileOnly && !isIOS && (caretRef.current.style.display = 'block')
                    // keyboardState.transliteration && updateTransliterationBoxPosition()
                }
            },
            [keyboardDispatch, keyboardState.active, onFocus, saveValueToLocalStorage]
        )

        const handleBlur = useCallback(() => {
            isMobileOnly && (caretRef.current.style.display = 'none')
            onBlur && onBlur()
        }, [onBlur])

        const handleClickJoint = useCallback(
            e => {
                // if(englishOnly) showEnglishKeyboard(true);
                if (transliterationState.transliteration) updateTransliterationBoxPosition()

                // transliterationState.transliteration && transliterationState.textToCheck && (transliterationDispatch({ type: "clear"}));

                // console.log("transliterationState.textToCheck",transliterationState.textToCheck)
                // console.log("keyboardState.mobileLoadKeyboard",keyboardState.mobileLoadKeyboard)

                // if(!keyboardState.mobileLoadKeyboard) {
                //   document.execCommand('selectAll', false, null);
                // }
                // if(isMobileOnly && !keyboardState.mobileLoadKeyboard) {
                //   keyboardDispatch({ type: "mobile-load-keyboard"});
                // }
            },
            [transliterationState.transliteration, updateTransliterationBoxPosition]
        )

        const handleTextAreaClickIos = useCallback(
            e => {
                if (keyboardState.active) {
                    onClick && onClick()
                    handleClickJoint(e)
                    innerRef.current.focus()
                    setTimeout(function() {
                        innerRef.current.removeAttribute('readonly')
                    }, 100)
                }
            },
            [handleClickJoint, innerRef, keyboardState.active, onClick]
        )

        const handleKeyDown = useCallback(
            e => {
                if (keyboardState.active) {
                    if (transliterationState.transliteration) {
                        if (transliterationState.textToCheck) {
                            let action = ''

                            // TODO: unite with transliterateBox.js (same code)
                            ;(e.keyCode === 40 || e.keyCode === 37) && (action = 'choice-left') // down/left arrow
                            ;(e.keyCode === 38 || e.keyCode === 39) && (action = 'choice-right') // up/right arrow
                            e.keyCode === 27 && (action = 'clear') // escape
                            ;(e.keyCode === 13 || e.keyCode === 32) && (action = 'done') // enter and space

                            action && transliterationDispatch({ type: action })
                            action && e.preventDefault()
                        } else {
                            if (e.keyCode !== 13 && e.keyCode !== 32 && e.keyCode !== 8) {
                                updateTransliterationBoxPosition() // enter space and backspace
                            }
                            onKeyDown && onKeyDown(e)
                        }
                    } else {
                        onKeyDown && onKeyDown(e)
                    }
                }
            },
            [
                keyboardState.active,
                onKeyDown,
                transliterationDispatch,
                transliterationState.textToCheck,
                transliterationState.transliteration,
                updateTransliterationBoxPosition
            ]
        )

        const setEditBarText = useCallback(
            val =>
                keyboardDispatch({
                    type: 'update-value',
                    payload: { key: dataSaveId, value: val }
                }),
            [dataSaveId, keyboardDispatch]
        )

        const handleContainerFocus = useCallback(() => {
            englishOnly && keyboardDispatch({ type: 'english-keyboard', payload: true })
            englishOnly && transliterationDispatch({ type: 'stash' })
        }, [englishOnly, keyboardDispatch, transliterationDispatch])

        const handleContainerBlur = useCallback(() => {
            englishOnly && keyboardDispatch({ type: 'english-keyboard', payload: false })
            englishOnly && transliterationDispatch({ type: 'unstash' })
            isMobileOnly && isIOS && keyboardDispatch({ type: 'focus-input', payload: false })
        }, [englishOnly, keyboardDispatch, transliterationDispatch])

        const handleContainerClick = useCallback(() => {
            if (transliterationState.transliteration) {
                transliterationState.textToCheck && transliterationDispatch({ type: 'clear' })
            }
        }, [transliterationDispatch, transliterationState.textToCheck, transliterationState.transliteration])

        const handleClick = useCallback(
            e => {
                handleClickJoint(e)

                if (isIE && browserVersion <= 10 && e.target.innerHTML) {
                    // fix <=ie10 - jumps to next line when trying to add chars to end of line
                    var startLine =
                        e.pageX - e.target.getBoundingClientRect().left < 15 ||
                        e.target.getBoundingClientRect().left + e.target.offsetWidth - e.pageX < 15
                    var range = document.selection.createRange()
                    range.moveStart('character', -1)
                    range.select()
                    if (!range.text[0] && !startLine) {
                        range.moveEnd('character', -1)
                    } else {
                        range.moveStart('character', range.text[0].length)
                    }
                    range.select()
                }

                isMobileOnly && insertCaretAtCursor()
                onClick && onClick()
            },
            [handleClickJoint, insertCaretAtCursor, onClick]
        )

        return (
            <div
                onKeyDown={handleKeyDown}
                onClick={handleContainerClick}
                onFocus={handleContainerFocus}
                onBlur={handleContainerBlur}
                style={{ width: '100%', height: '100%', position: 'relative' }}
            >
                {!isIOS && (
                    <>
                        {isMobileOnly && (
                            <>
                                <input className='ghostInput' onFocus={e => e.target.blur()} ref={ghostInput} />
                                <span
                                    className='text_caret text_caret_mobile'
                                    ref={caretRef}
                                    style={{ display: isMobileOnly ? 'block' : 'none' }}
                                />
                                <span className='calc_text_caret' ref={calcCaretRef} />
                            </>
                        )}

                        <ContentEditable
                            aria-label='Text Area'
                            className={`ContentEditable ${adaptiveFontSize ? fontSizeClass : ''} ${className}`}
                            innerRef={innerRef}
                            html={keyboardState[dataSaveId] || ''} // innerHTML of the editable div
                            onChange={handleChange} // handle innerHTML change
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            disabled={isMobileOnly && keyboardState.active}
                            onTouchEnd={() => isMobileOnly && ghostInput.current.focus()}
                            placeholder={typeHereString}
                            onClick={handleClick}
                            // tagName='article' // Use a custom HTML tag (uses a div by default)
                            {...props}
                        />
                    </>
                )}
                {isIOS && (
                    <textarea
                        className={`ContentEditable ${adaptiveFontSize ? fontSizeClass : ''} ${className}`}
                        value={keyboardState[dataSaveId] || ''}
                        ref={innerRef}
                        onInput={handleChange} // handle innerHTML change
                        onFocus={handleFocus}
                        onClick={handleTextAreaClickIos}
                        onBlur={handleTextAreaBlurIos}
                        readOnly={keyboardState.active ? true : ''}
                        placeholder={typeHereString}
                        // tagName='article' // Use a custom HTML tag (uses a div by default)
                        {...props}
                    />
                )}
                {/* note: <Suspense> here made problems in concurrent loading */}
                {showEditBar && (
                    <EditBar
                        rtl={rtl}
                        text={keyboardState[dataSaveId]}
                        setText={setEditBarText}
                        editableRef={innerRef}
                    />
                )}
            </div>
        )
    }
)

export default PersistentContentEditable
