import { useCallback, useEffect, useState } from 'react'
import { matchPath } from 'react-router'
import { keyboardLanguages, keyboardSections, locales, sitePath } from '../StaticData/SiteData.js'

const queryString = require('query-string') // If you want support for older browsers, or use version 5: npm install query-string@5.

const page = {
    locale: null,
    kblang: null,
    section: null
}

export default function useResolveUrl(history, i18n) {
    const location = history.location
    const resolve = useCallback(
        loc => {
            const getMatch = loc =>
                matchPath(loc.pathname.toLowerCase(), {
                    path:
                        `:app_prefix(/nocache)?/:locale(${locales.getForPath().toLowerCase()})?` +
                        `/:kbLangSlug(${keyboardLanguages.getAllSlugsForPath(i18n).toLowerCase()})?` +
                        `/:sectionSlug(${keyboardSections.getAllSlugsForPath(i18n).toLowerCase()}|babylon)?`,
                    sensitive: false
                })
            const match = getMatch(loc)
            const resolvedParts = match
                ? {
                      ...match.params,
                      parsedHash: queryString.parse(loc.hash),
                      parsedSearch: queryString.parse(loc.search)
                  }
                : {}
            let { app_prefix, locale, kbLangSlug, sectionSlug, parsedHash, parsedSearch } = resolvedParts

            locale = locale || i18n.language
            app_prefix = app_prefix || ''

            if (sectionSlug === 'babylon') sectionSlug = keyboardSections.keyToLocSlug('trans', i18n)

            return { app_prefix, locale, kbLangSlug, sectionSlug, parsedHash, parsedSearch }
        },
        [i18n]
    )

    const [resolvedParts, setResolvedParts] = useState(() => resolve(location))
    const { app_prefix, locale, kbLangSlug, sectionSlug, parsedHash, parsedSearch } = resolvedParts
    const resolvedPath = sitePath.slugsToPath(kbLangSlug, sectionSlug, parsedSearch, i18n, locale)
    const rightPath = app_prefix + resolvedPath + location.hash
    const incomingPath = decodeURI(location.pathname + location.search)

    // console.log('resolved:', resolvedPath, 'rightPath:', rightPath)

    const shouldChangeUrl = useCallback(() => {
        if (resolvedPath !== incomingPath) {
            location.search ? history.push(rightPath) : history.replace(rightPath)
            // location.search used for legacy urls that should be redirected
        }
    }, [history, incomingPath, location.search, resolvedPath, rightPath])

    useEffect(() => {
        const unlisten = history.listen(loc => {
            setResolvedParts(resolve(loc))
            if (typeof window !== 'undefined' && page.locale) {
                window.ga('set', 'page', loc.pathname + loc.search)
                window.ga('send', 'pageview')
            }
        })
        shouldChangeUrl()
        return unlisten
    }, [history, resolve, shouldChangeUrl])

    shouldChangeUrl() // for ssr (ssr doesnt run useEffect)

    // useEffect( ()=>{
    //   if(locale !== i18n.language) { //changing language
    //     i18n.changeLanguage(resolvedParts.locale,()=>setResolvedParts(resolve(location)))
    //   }
    // },[locale])

    // state.history.push(action.payload.url)

    const kbLangKey = keyboardLanguages.slugToKey(kbLangSlug, i18n)
    const sectionKey = kbLangSlug ? keyboardSections.slugToKey(sectionSlug, i18n) : 'home' // keyboardSections.slugToKey(sectionSlug)

    // send page level ga dimensions (via gtm)
    if (typeof window !== 'undefined') {
        if (
            (sectionKey === 'home' || (kbLangKey && sectionKey)) &&
            (page.section !== sectionKey || page.kblang !== kbLangKey || page.locale !== i18n.language)
        ) {
            // window.dataLayer.push({
            //     event: 'update_page_dimensions',
            //     page_locale: i18n.language,
            //     page_kblang: kbLangKey,
            //     page_section: sectionKey
            // })
        }
    }
    page.kblang = kbLangKey
    page.section = sectionKey
    page.locale = i18n.language

    return { locale, kbLangKey, sectionKey, parsedHash, parsedSearch }
}
