import React, { Suspense, useEffect, useRef, useState } from 'react'
import { AddThisToolbox, addthisLoad } from './AddThis'
import './SocialBottom.scss'
import useFacebook from './useFacebook'
import TrackVisibility from 'react-on-screen'

import AdsenseAd from './AdsenseAd'
import Loader from './Loader'
import { sitePath } from '../StaticData/SiteData'

// let lastId = 0
const Footer = React.lazy(() => import('./Footer'))

const FooterWithSocialTracked = React.memo(({ sectionKey, kbLangKey, locale, i18n, changeLanguage, isVisible }) => {
    const socialBottomRef = useRef(`socialBottom`)

    const { loadFacebook } = useFacebook()
    const [showFooter, setShowFooter] = useState(false)

    useEffect(() => {
        if (isVisible && !showFooter) {
            setShowFooter(true)
        }
    }, [isVisible, showFooter])

    useEffect(() => {
        if (showFooter) {
            loadFacebook()
            addthisLoad()
        }
    }, [loadFacebook, showFooter])

    return (
        <div>
            <div id={socialBottomRef.current}>
                <div className={'social-bottom'}>
                    <AddThisToolbox url={sitePath.keysToPath(kbLangKey, sectionKey, i18n, locale)} />
                    <div
                        className='fb-like'
                        data-href='http://www.facebook.com/Gate2Home'
                        data-layout='standard'
                        data-action='recommend'
                        data-width='310'
                        data-size='small'
                        data-show-faces='false'
                        data-share='false'
                    />
                </div>
            </div>

            <div className={'social-bottom'}>
                {showFooter && (
                    <AdsenseAd
                        adSlot='3974121641'
                        updateKey={'lazy-bottom-unit' + kbLangKey + sectionKey}
                        adFormat='auto'
                    />
                )}
            </div>

            <Suspense fallback={<Loader />}>{showFooter && <Footer changeLanguage={changeLanguage} />}</Suspense>
        </div>
    )
})

const FooterWithSocial = React.memo(props => (
    <TrackVisibility partialVisibility once>
        <FooterWithSocialTracked {...props} />
    </TrackVisibility>
))

export default FooterWithSocial
