let diff
if (process.env.NODE_ENV === 'development') {
    diff = require('deep-object-diff').diff
}

const logState = (prevState, newState, reducer_name, action) => {
    if (process.env.NODE_ENV === 'development') {
        const diffedState = diff(prevState, newState)

        const actionType = action.type.toUpperCase()
        const payload = action.payload
            ? JSON.stringify(action.payload, null, 2 * (JSON.stringify(action.payload).length < 30))
            : ''
        const difffedState = JSON.stringify(diffedState, null, 2 * (JSON.stringify(diffedState).length < 30)) // TODO option if making it generic, return type,payload,diff so can be printted outside

        // printCallback([`%c ${actionType}` + '\r\n' +

        console.log(
            `%c ${reducer_name}:${actionType}\r\n%c payload:%c ${payload} \r\n%c diff:%c ${difffedState}`,
            'background: #00ff00; color: #ff',
            'background: #fff; color: #0000ff;  font-weight: bold;',
            'background: #fff; color: #00',
            'background: #fff; color: #0000ff;  font-weight: bold;',
            'background: #fff; color: #00'
        )
    }
}

// using callback so console log would be from the location called in code
export const withLog = (reducerFunc, reducer_name) => {
    return (state, action) => {
        const newState = reducerFunc(state, action)
        if (process.env.NODE_ENV === 'development') {
            logState(state, newState, reducer_name, action)
        }
        return newState
    }
}
