import i18next from 'i18next'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
// import { useTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import Gate2HomeContext from '../../Context/Gate2HomeContext'
import { keyboardLanguages, sitePath } from '../../StaticData/SiteData.js'
import '../../Styles/shared.scss'
import './Home.scss'

import keyboardFadeImage from './keyboard-fade3.png'
// import Container from 'react-bootstrap/lib/Container'
// import Row from 'react-bootstrap/lib/Row'
// import Col from 'react-bootstrap/lib/Col'
import starterBgTransImage from './starterbgtrans.png'
import DropButtonMenu from '../../Components/DropButtonMenu'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    backgroundImage: {
        position: 'relative',
        marginTop: '47px',
        zIndex: '-1',
        width: '930px',
        left: '6px',
        maxWidth: '100%',
        height: 'auto',
        verticalAlign: 'middle',
        border: '0',
        minWidth: 'auto',
        textAlign: 'center',
        marginLeft: '26px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            top: '51px',
            width: 'auto'
        }
    },
    starterInputs: {
        top: '-226px',
        position: 'relative',
        color: 'white',
        fontFamily: 'Georgia',
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: '22px'
    },
    lowerText: {
        margin: '1px 0 9px 0',
        textShadow: '1px 1px 0.1px #1c3e5a'
    },
    keyboardFade: {
        top: '-219px',
        left: '96px',
        opacity: '0.4',
        zIndex: '-3',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    starterH1: {
        textShadow: '1px 1px 0.1px #1c3e5a',
        color: 'white',
        fontFamily: 'Georgia',
        fontSize: '36px',
        marginBottom: '37px',
        marginTop: '6px',
        lineHeight: '36px',
        fontStyle: 'italic',
        fontWeight: 'normal',

        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '158px',
            fontSize: '27px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '35px',
            marginTop: '80px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '35px',
            marginTop: '5px'
        }
    },
    starterText: {
        height: '262px',
        marginTop: '158px',
        [theme.breakpoints.down('sm')]: {
            // height: '361px',
            height: 'auto !important',
            overflow: 'hidden',
            position: 'relative'
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto !important',
            overflow: 'hidden',
            position: 'relative'
        }
    },
    starterSelector: {
        margin: '83px 60px 124px 138px',
        width: '500px',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            margin: '32px auto 30px auto'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            margin: '66px auto 100px auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block'
        }
    },
    starterSelect: {
        width: '350px',
        height: '43px',
        marginRight: '4px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#6f6f6f',
        top: '1px',
        position: 'relative',
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: '330px',
            marginRight: '1px',
            marginBottom: '12px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    starterForm: {
        display: 'inline-block',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
            float: 'none'
        }
    },
    starterButton: {
        margin: theme.spacing(0.5),
        direction: 'ltr',
        padding: '6px 9px 6px 12px',
        fontWeight: 'bold',
        backgroundColor: '#7e9e41 !important',
        '&:hover': {
            filter: 'brightness(122%)'
        },
        wordWrap: 'normal',
        width: '130px',
        marginLeft: '18px',
        fontFamily: 'Arial',
        bottom: '4px',
        height: '44px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            marginLeft: '0'
        }
    }
}))

//= ========================
function Home({ isLanding }) {
    const [t, i18n] = useTranslation()
    const classes = useStyles()

    const Gate2HomeState = useContext(Gate2HomeContext.State)
    const Gate2HomeDispatch = useContext(Gate2HomeContext.Dispatch)

    // const [t, i18n] = useTranslation(['BRAND','UI','LANG'],{i18next})
    const selectLanguageEl = useRef(null)

    useEffect(() => {
        Gate2HomeDispatch({
            type: 'load-key-from-stroage',
            payload: ['selectedLanguage', 'lastLanguage', 'lastLocale']
        })
    }, [Gate2HomeDispatch])

    const handleContinue = useCallback(() => {
        if (selectLanguageEl.current.value !== 'none') {
            // SEND_EVENT: 'homepage_continue'
            Gate2HomeDispatch({
                type: 'go-to-url',
                payload: { url: sitePath.keysToPath(selectLanguageEl.current.value, '', i18n) }
            })
        } else {
            // SEND_EVENT: 'homepage_continue_while_none'
        }
        // if (selected === "no") {
        //     return;
        // }
        // localStorage.setItem("selected_language", selected)
    }, [Gate2HomeDispatch, i18n])

    useEffect(() => {
        if (isLanding && !window.location.href.split('/')[3]) {
            // if landing and for sure home in url
            Gate2HomeDispatch({
                type: 'save-to-storage',
                payload: { key: 'goToKeyboard', value: true }
            })
        }
    }, [Gate2HomeDispatch, isLanding])

    useEffect(() => {
        if (Gate2HomeState.goToKeyboard && Gate2HomeState.selectedLanguage) {
            Gate2HomeDispatch({
                type: 'save-to-storage',
                payload: { key: 'goToKeyboard', value: false }
            })
            handleContinue()
        }
    }, [Gate2HomeDispatch, Gate2HomeState.goToKeyboard, Gate2HomeState.selectedLanguage, handleContinue])

    // if first time here and from keyboard page, choose that keyboard
    useEffect(() => {
        if (Gate2HomeState.lastLanguage && !Gate2HomeState.selectedLanguage) {
            Gate2HomeDispatch({
                type: 'save-to-storage',
                payload: { key: 'selectedLanguage', value: Gate2HomeState.lastLanguage }
            })
        }
    }, [Gate2HomeDispatch, Gate2HomeState.lastLanguage, Gate2HomeState.selectedLanguage])

    // if landing page is '/' and lastLocale exists, change to it ('/' is unique page, defaults to 'en' until other language was chosen on client)
    useEffect(() => {
        if (Gate2HomeState.lastLocale && i18n.language === 'en' && Gate2HomeState.lastLocale !== 'en') {
            i18next.changeLanguage(Gate2HomeState.lastLocale)
            Gate2HomeDispatch({ type: 'save-to-storage', payload: { key: 'lastLocale', value: '' } })
        }
    }, [Gate2HomeDispatch, Gate2HomeState.lastLocale, i18n.language])

    const handleSelectChange = evt => {
        Gate2HomeDispatch({
            type: 'save-to-storage',
            payload: { key: 'selectedLanguage', value: evt.target.value }
        })
    }

    return (
        <div className='Home'>
            <img
                src={starterBgTransImage}
                className={classes.backgroundImage}
                alt='background'
                width='930'
                height='364'
            />
            <div id='starter-inputs' className={classes.starterInputs} role='main form'>
                <div className={classes.starterText}>
                    <h1 className={classes.starterH1}>{t('BRAND:Type in your language, anywhere!')}</h1>
                    <div className='hr-long-start' />
                    <div className={classes.starterSelector}>
                        <form onSubmit={handleContinue} className={classes.starterForm}>
                            <select
                                className={classes.starterSelect}
                                ref={selectLanguageEl}
                                onChange={handleSelectChange} // handle innerHTML change
                                value={Gate2HomeState.selectedLanguage ? Gate2HomeState.selectedLanguage : 'none'}
                            >
                                <option key='none' value='none'>
                                    {t('UI:Select Your Language')}:
                                </option>
                                {keyboardLanguages.allSortedLocaleNamePlusNative(i18n, false).map(obj => (
                                    <option key={obj.key} value={obj.key}>
                                        {obj.value}
                                    </option>
                                ))}
                            </select>
                        </form>
                        <DropButtonMenu
                            text={t('UI:Continue')}
                            className={classes.starterButton}
                            onClick={handleContinue}
                            eventKey='continue'
                        />
                    </div>
                    <div className='hr-long-start' />
                    <div style={{ height: 0 }}>
                        <img
                            className={classes.keyboardFade}
                            src={keyboardFadeImage}
                            alt='keyboard fading in the background'
                            width='491'
                            height='189'
                        />
                    </div>
                    <div className='hr-long-start' />
                    <div className={classes.lowerText}>
                        {t('BRAND:The best solution available for an on-screen virtual keyboard emulator')}
                    </div>
                    <div className='hr-long-start' />
                </div>
            </div>
        </div>
    )
}

export default Home
