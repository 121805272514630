import React, { useCallback, useContext } from 'react'
// import { useTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { extractContent } from '../../../aux.js'
import Gate2HomeContext from '../../../Context/Gate2HomeContext'
import KeyboardContext from '../../../Context/KeyboardContext'
import { sitePath, translateSwapMapping } from '../../../StaticData/SiteData'
import DropButtonMenu from '../../../Components/DropButtonMenu.js'

import SearchIcon from '@material-ui/icons/Search'
import ShareIcon from '@material-ui/icons/Share'

import TranslateIcon from '@material-ui/icons/Translate'

const ActionBar = React.memo(() => {
    const [t, i18n] = useTranslation()

    const keyboardState = useContext(KeyboardContext.State)
    const Gate2HomeState = useContext(Gate2HomeContext.State)
    const Gate2HomeDispatch = useContext(Gate2HomeContext.Dispatch)

    const handleTranslate = useCallback(() => {
        // window.open('https://www.youtube.com/results?search_query=' + extractContent(Gate2HomeState.storage['textbox1']) , '_blank');

        const resolvedPath = sitePath.keysToPath(
            Gate2HomeState.kbLangKey,
            'trans',
            i18n,
            Gate2HomeState.locale,
            '#lang=' +
                (Gate2HomeState.locale !== Gate2HomeState.kbLangKey
                    ? translateSwapMapping.kb2goog(Gate2HomeState.locale)
                    : 'en') +
                '&t=' +
                extractContent(keyboardState.textbox1, { oneLine: false })
        )

        Gate2HomeDispatch({ type: 'go-to-url', payload: { url: resolvedPath } })
    }, [Gate2HomeDispatch, Gate2HomeState.kbLangKey, Gate2HomeState.locale, i18n, keyboardState.textbox1])

    return (
        <div className='action-menu' style={{ color: 'white' }}>
            <DropButtonMenu
                text={t('UI:(button)Share')}
                bgColor='#9385cc'
                Icon={ShareIcon}
                eventKey='share'
                which={'share'}
            />

            <DropButtonMenu
                text={t('UI:(button)Translate')}
                bgColor='#419c8b'
                Icon={TranslateIcon}
                onClick={handleTranslate}
                eventKey='translate'
            />

            <DropButtonMenu
                text={t('UI:(button)Search')}
                bgColor='#3480fc'
                Icon={SearchIcon}
                eventKey='search'
                which={'search'}
            />
        </div>
    )
})

export default ActionBar
