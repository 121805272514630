import { useContext, useState } from 'react'
import { extractContent } from '../aux.js'
import KeyboardContext from '../Context/KeyboardContext'

const facebookSdkUrl = 'https://connect.facebook.net/en_US/sdk.js'

const useFacebook = () => {
    const keyboardState = useContext(KeyboardContext.State)
    const [facebookDisabled, setFacebookDisabled] = useState(false)
    const [facebookLoaded, setFacebookLoaded] = useState(false)

    const loadFacebook = (callback = false) => {
        setFacebookDisabled(true)
        // cGate2HomeDispatch({ type: "facebook-disable", payload: true});

        window.fbAsyncInit = function() {
            window.FB.init({
                appId: '135016923220292',
                autoLogAppEvents: true,
                xfbml: true,
            version: 'v3.2'
            })
        }

        import('scriptjs').then(scriptjs =>
            scriptjs.default(facebookSdkUrl, () => {
                setFacebookLoaded(true)
                setFacebookDisabled(false)
                if (callback) callback()
                return true
            })
        )
    }

    const publishToFacebook = () => {
        // ga('send', 'event', 'Facebook', '3. Calling UI Share', kb_slug, 1, {'nonInteraction': 1});

        window.FB.ui(
            {
                method: 'share',
                display: 'popup',
                href: window.location.href,
                quote: extractContent(keyboardState.textbox1)
            },
            function(response) {
                if (!response || response.error) {
                    // ga('send', 'event', 'Facebook', '3B. Post wasn\'t Shared', kb_slug, 1, {'nonInteraction': 1});
                    var fb_err_title = 'Opps...'
                    // ((typeof response.error.error_user_title)  !== "undefined" ? response.error.error_user_title + ": " : "") +
                    var fb_err_msg =
                        response && typeof response.error.error_user_msg !== 'undefined'
                            ? response.error.error_user_msg
                            : 'Error occurred, Post was not published to Facebook!'
                    // showDialog(fb_err_title,fb_err_msg ,false);
                    console.log(fb_err_title + ' ' + fb_err_msg)
                } else {
                    // ga('send', 'event', 'Facebook', '3A. Post Shared', kb_slug, 1, {'nonInteraction': 1});
                    // showDialog("Great Success!","Status update was published to your facebook timeline",false);
                    console.log(fb_err_title + ' ' + fb_err_msg)
                }
            }
        )
    }

    const handleFacebook = () => {
        if (!facebookLoaded) loadFacebook(publishToFacebook)
        else publishToFacebook()
    }

    return { facebookDisabled, handleFacebook, loadFacebook }
}

export default useFacebook
