// import React, {useReducer, useCallback} from 'react'
// import {withLog} from './withLog'

// let withLog = reducer => reducer
// if(process.env.NODE_ENV === 'development') {
//   import('./withLog').then( module =>{
//     withLog = module.withLog
//   })
// }
import { local as storeLocal } from '../Config/storage'

const storageReducer = (state, action) => {
    switch (action.type) {
        case 'load-key-from-stroage':
            if (!Array.isArray(action.payload)) {
                var item = storeLocal.getItem('g2h_' + action.payload)
                return { ...state, [action.payload]: item }
            } else {
                return {
                    ...state,
                    ...action.payload.reduce((acc, key) => {
                        acc[key] = storeLocal.getItem('g2h_' + key)
                        return acc
                    }, {})
                }
            }
        case 'save-to-storage':
            var val = action.payload.value
            val = val || ''
            storeLocal.setItem('g2h_' + action.payload.key, val)
            return { ...state, [action.payload.key]: val }
        default:
            return state
    }
}

const combineReducers = (reducer1, reducer2) => {
    return (state, action) => reducer2(reducer1(state, action), action)
}

export { storageReducer, combineReducers }
