import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import { sitePath, keyboardLanguages } from '../../StaticData/SiteData'

const useStyles = makeStyles(theme => ({
    bread: {
        color: '#bfbfbf',
        fontSize: '15px'
    },
    link: {
        display: 'flex',
        color: '#bfbfbf',
        fontSize: '15px',
        direction: 'ltr',
        textDecoration: 'none',
        '&:hover': {
            color: '#ffffff !important'
        }
    },
    icon: {
        marginLeft: theme.spacing(0.7),
        width: 20,
        height: 20
    }
}))

const section2locize = {
    trans: 'UI:(section)Translation',
    google: 'UI:(button)Google',
    wiki: 'UI:(button)Wikipedia',
    email: 'UI:(section)Send Email'
}

export default function LogoBreadcrumbs({ t, i18n, kbLangKey, sectionKey }) {
    const classes = useStyles()

    return (
        <Breadcrumbs aria-label='breadcrumb' separator='/' className={classes.bread}>
            <Link
                className={classes.link}
                // className='UpperLeftLogo g2hlogo-up'
                to={sitePath.keysToPath('', '', i18n)}
                aria-label={t('UI:Change keyboard language')}
            >
                {/* <Link color='inherit' href='/' className={classes.link}> */}
                <HomeIcon className={classes.icon} />
                <span className='g2hlogo-up' style={{ paddingLeft: '5px' }}>
                    Gate2Home
                </span>
            </Link>
            {sectionKey !== 'freetext' ? (
                <Link
                    to={sitePath.keysToPath(kbLangKey, '', i18n)}
                    // style="text-decoration: none;"
                    className={classes.link}
                >
                    {keyboardLanguages.keyToLocaleName(kbLangKey, i18n)}
                </Link>
            ) : (
                <span color='textPrimary' className={classes.link}>
                    {keyboardLanguages.keyToLocaleName(kbLangKey, i18n)}
                </span>
            )}

            {sectionKey !== 'freetext' && <span className={classes.link}>{t(section2locize[sectionKey])}</span>}
        </Breadcrumbs>
    )
}
