import React, { Suspense, lazy, useRef, useEffect, useState, useCallback } from 'react'
import Loader from './Loader'

// Lazy load only on client, when condition is met
// exmaple:
/* 
    <LazyLoad id='AlertDialog'
        condition={dialogProps.open}
        reactLazyFunc={() => import('../../../Components/AlertDialog')}
        setOpen={flag=>setDialogProps({...dialogProps,open:flag})}
        handleApprove={()=>handleSend(false)}
        {...dialogProps} 
    /> 
*/
export const LazyLoad = ({ id, reactLazyFunc, condition = true, ...props }) => {
    // const [loaded, setLoaded] = useState(false)
    const LazyComponent = useRef(lazy(reactLazyFunc))

    return (
        <span key={id + 'Container'} id={id + 'Container'}>
            <Suspense fallback={<Loader />}>{condition && <LazyComponent.current {...props} />}</Suspense>
        </span>
    )
}

export const LazyMenu = ({ resetKey, reactLazyFunc, className, caption, id, ButtonA, ...props }) => {
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = useCallback(() => {
        setMobileMoreAnchorEl(null)
        handleMobileMenuClose()
    }, [])

    useEffect(() => {
        handleMenuClose()
    }, [handleMenuClose, resetKey])

    return (
        <>
            <ButtonA onClick={handleMobileMenuOpen} />

            <LazyLoad
                id={id}
                condition={mobileMoreAnchorEl}
                reactLazyFunc={reactLazyFunc}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                onClose={handleMenuClose}
                {...props}
            />
        </>
    )
}
