import React, { useCallback, useReducer } from 'react'
import { combineReducers, storageReducer } from './Contexter'
import { withLog } from './withLog'

const initialState = {
    locale: '', // fr (ui language)
    kbLangKey: '', // he (keyboard)
    sectionKey: '', // section
    history: {},
    parsedHash: {}, // {q: 'search term', ...}
    parsedSearch: {}
    // mobileLoadAdsense: true
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'update-state':
            return { ...state, ...action.payload }
        case 'go-to-url':
            state.history.push(action.payload.url)
            return state
        // case 'reset':
        //   return initialState
        // case 'increment':
        //   return { ...state, count: state.count + 1 }
        // case 'decrement':
        //   return { ...state, count: state.count - 1 }
        // case 'set-color':
        //   return { ...state, currentColor: action.payload }
        // case 'SET-KEYBOARD-LANGUAGE':
        //   return { ...state, kbLang: action.payload}
        // case 'set-section':
        //   return { ...state, section: action.payload}
        default:
            return state
    }
}

const State = React.createContext()
State.displayName = 'Gate2HomeState'
const Dispatch = React.createContext()
Dispatch.displayName = 'Gate2HomeDispatch'

const combinedReducer = withLog(combineReducers(reducer, storageReducer), 'Gate2Home')

const Provider = ({ children }) => {
    const memoizedReducer = useCallback(combinedReducer, [reducer, storageReducer])
    const [state, dispatch] = useReducer(memoizedReducer, initialState)

    return (
        <Dispatch.Provider value={dispatch}>
            <State.Provider value={state}>{children}</State.Provider>
        </Dispatch.Provider>
    )
}

export default { State, Dispatch, Provider }
