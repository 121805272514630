import React, { useEffect, useState } from 'react'
// import Gate2HomeContext from '../Context/Gate2HomeContext'
import './AdsenseAd.scss'
import TrackVisibility from 'react-on-screen'

// const mobileBreakpoint = '650'
// const queryMobile = `(max-width:${mobileBreakpoint}px)`

export const AdsenseAdTrackedInternal = ({
    adSlot,
    adFormat = 'auto',
    style = { display: 'block' },
    responsive = true,
    className = '',
    updateKey,
    isVisible = true,
    ...props
}) => {
    // const [isMobileMedia] = useState(typeof window !== 'undefined' ? window.matchMedia(queryMobile).matches : false)
    // const Gate2HomeState = useContext(Gate2HomeContext.State)
    const [showAd, setShowAd] = useState(false)

    useEffect(() => {
        if (isVisible && !showAd) {
            setShowAd(true)
        }
    }, [isVisible, showAd])

    useEffect(() => {
        // ;(!isMobileMedia || Gate2HomeState.mobileLoadAdsense) &&
        if (showAd) {
            import('scriptjs').then(scriptjs =>
                scriptjs.default('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', () => {
                    const handler = () => {
                        ;(window.adsbygoogle || []).push({})
                    }

                    if (document.readyState === 'complete') {
                        handler()
                    } else {
                        window.addEventListener('load', handler)
                    }
                })
            )
        }
    }, [updateKey, showAd])

    return (
        <>
            {showAd ? (
                <ins
                    key={updateKey}
                    className={'adsbygoogle ' + className + (process.env.NODE_ENV !== 'production' ? ' dev' : '')}
                    data-ad-client='ca-pub-0070744872185334'
                    data-ad-slot={adSlot}
                    style={style}
                    data-ad-format={adFormat}
                    data-full-width-responsive={responsive}
                    data-adsbygoogle-status={false}
                    {...props}
                ></ins>
            ) : (
                <div className={className}></div>
            )}
        </>
    )
}

const AdsenseAd = React.memo(props => (
    <TrackVisibility partialVisibility once>
        <AdsenseAdTrackedInternal {...props} />
    </TrackVisibility>
))

export default AdsenseAd
