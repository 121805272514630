import React, { Suspense, useCallback, useContext, useEffect, useRef } from 'react'

import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

import './App.css'
import DenseAppBar from './Components/DenseAppBar'
// import NotFound from './Pages/NotFound'
import Loader from './Components/Loader'
import HeadMeta from './Components/HeadMeta'
// import {FirebaseContext} from './Context/FirebaseContext'
import Gate2HomeContext from './Context/Gate2HomeContext'
import KeyboardContext from './Context/KeyboardContext'
import useResolveUrl from './Hooks/useResolveUrl.js'
// import loadable from '@loadable/component'
import Keyboard from './Pages/Keyboard/'
import { sitePath } from './StaticData/SiteData.js'
import './Styles/gate2home.scss'

import { useSSR } from 'react-i18next'

import { I18nextProvider } from 'react-i18next'
import theme from './Components/theme'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@material-ui/styles'
import FooterWithSocial from './Components/FooterWithSocial'
// import SentryErrorBoundary from './Config/SentryErrorBoundary'
import Home from './Pages/Home/'
// const Home = React.lazy(() => import('./Pages/Home/'))

const Page = React.memo(({ history }) => {
    const [t, i18n] = useTranslation()

    const firstUrlFlag = useRef(true)

    const Gate2homeState = useContext(Gate2HomeContext.State)
    const Gate2HomeDispatch = useContext(Gate2HomeContext.Dispatch)

    const resolvedParts = useResolveUrl(history, i18n)

    const { locale, kbLangKey, sectionKey } = resolvedParts
    // if asking for  search results

    const prevLocationRef = useRef(null)

    useEffect(() => {
        if (history.location !== prevLocationRef.current) {
            prevLocationRef.current = history.location
            Gate2HomeDispatch({ type: 'update-state', payload: { history, ...resolvedParts } })
            if (Gate2homeState.lastLanguage !== kbLangKey)
                Gate2HomeDispatch({ type: 'save-to-storage', payload: { key: 'lastLanguage', value: kbLangKey } })
            if (sectionKey !== 'home' && Gate2homeState.lastLocale !== locale)
                Gate2HomeDispatch({ type: 'save-to-storage', payload: { key: 'lastLocale', value: locale } })
        }
    }, [
        Gate2HomeDispatch,
        Gate2homeState.lastLanguage,
        Gate2homeState.lastLocale,
        history,
        history.location,
        kbLangKey,
        locale,
        resolvedParts,
        sectionKey
    ])

    useEffect(() => {
        // if (!snap && !firstUrlFlag.current) {
        //     ReactGA.pageview(history.location.pathname + history.location.search)
        // }
        firstUrlFlag.current = false
        window.scrollTo(0, 0)
    }, [history.location.pathname, history.location.search])

    const changeLanguage = useCallback(
        lng =>
            i18n.changeLanguage(lng, () => {
                Gate2HomeDispatch({
                    type: 'go-to-url',
                    payload: { url: sitePath.keysToPath(kbLangKey, sectionKey, i18n) }
                }) // will change to locale url
            }),
        [Gate2HomeDispatch, i18n, kbLangKey, sectionKey]
    )

    return (
        <div className={`App dir-${i18n.dir()}`}>
            {/* ${Gate2homeState.mobileLoadAdsense ? 'mobileLoadAdsense' : ''}`}> */}
            <div key='HeadMetaContainer' id='HeadMetaContainer'>
                {resolvedParts && <HeadMeta {...{ i18n, resolvedParts, t }} />}
            </div>

            <Suspense>
                <DenseAppBar i18n={i18n} t={t} {...resolvedParts} changeLanguage={changeLanguage} history={history} />
            </Suspense>

            <Suspense>
                <>
                    {kbLangKey && (
                        <Keyboard
                            i18n={
                                i18n // if a 'keyboard' section (freetext, google ...)
                            }
                            {...resolvedParts}
                        />
                    )}

                    {!kbLangKey && (
                        <Home
                            i18n={i18n}
                            isLanding={
                                !prevLocationRef.current // if 'home' section is the initial ladning page
                            }
                        />
                    )}
                </>
            </Suspense>

            <Suspense fallback={<Loader />}>
                <FooterWithSocial {...resolvedParts} changeLanguage={changeLanguage} i18n={i18n} />
            </Suspense>
        </div>
    )
})

const PageWithRouter = withRouter(Page)

// if (typeof navigator !== 'undefined') {
//     console.log(
//         `%c

//    ▄▄▄▄▄        ▄          ▄███▄  ▄▄   ▄▄                       ▄█████▄
//   ██▀  ▀   ▄▄▄  █▄▄▄  ▄▄▄  ▀▀ ▀█▌ █▌   ██  ▄▄▄  ▄▄▄▄▄▄▄▄   ▄▄▄  █▄█▄█▄█▌
//  ██▌  ▄▄▄ ▀  ██ ██▀▀ █▀ ▀█   ▄██  ███████ █▀ ▀█ ██  ██ ▀█ █▀ ▀█  ▀███▀
//  ▐██   ██ █▀▀██ ██   ██▀▀▀ ▄█▀    █▌   ██ █▄ ▄█ ██  ██  █ █▀▀▀▀ ▀▀
//   ▀▀▀▀▀▀   ▀▀▀▀  ▀▀▀  ▀▀▀▀ ██████ ▀▀   ▀▀  ▀▀▀  ▀▀  ▀▀  ▀  ▀▀▀▀    .com
//                                                 Online Virtual Keyboard `,
//         'background: #3d86bc; color: white'
//     )
// }

const App = React.memo(({ helmetContext = {}, i18n }) => {
    useSSR(
        typeof window !== 'undefined' ? window.initialI18nStore : undefined,
        typeof window !== 'undefined' ? window.initialLanguage : undefined
    )

    useEffect(() => {
        //https://material-ui.com/guides/server-rendering/
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            setTimeout(() => jssStyles.parentNode.removeChild(jssStyles), 500)
        }
    }, [])

    return (
        // <SentryErrorBoundary>
        <Suspense fallback={<Loader />}>
            <HelmetProvider context={helmetContext}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={theme}>
                        {/* <FirebaseContext.Provider> */}
                        <Gate2HomeContext.Provider>
                            <KeyboardContext.Provider>
                                <PageWithRouter i18n={i18n} />
                            </KeyboardContext.Provider>
                        </Gate2HomeContext.Provider>
                        {/* </FirebaseContext.Provider> */}
                    </ThemeProvider>
                </I18nextProvider>
            </HelmetProvider>
        </Suspense>
        // </SentryErrorBoundary>
    )
})

export default App
