import React, { Suspense, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import FreeText from './FreeText'

import TransliterationContext from '../../Context/TransliterationContext'

// import loadable from '@loadable/component'

// import VirtualKeyboard from '../../Components/VirtualKeyboard'

import { keyboardLanguages, sitePath } from '../../StaticData/SiteData.js'

import Loader from '../../Components/Loader'
// import Sticky from 'react-sticky-el'
import { Grid, Button } from '@material-ui/core'
import ActionBar from './FreeText/ActionBar'
import { LazyLoad } from '../../Components/LazyLoad'
import '../../Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/ipad/keyboard_initial.min.css' // not dynamic - for react-snap inlinedcss
import '../../Components/VirtualKeyboard/VirtualKeyboard.scss'

// const LazyClient = src => ssr ? () => null : React.lazy(() => import(src))
import AdsenseAd, { AdsenseAdTrackedInternal } from '../../Components/AdsenseAd'
// const AdsenseAdLazy = React.lazy(() => import('../../Components/AdsenseAd'))

const Translate = React.lazy(() => import('./Translate'))
const EmailLazy = React.lazy(() => import('./Email'))
const VirtualKeyboardLazy = React.lazy(() => import('../../Components/VirtualKeyboard'))

const GoogleLazy = React.lazy(() => import('./Google'))
const GoogleResultsIframeLazy = React.lazy(() => import('./Google/GoogleResultsIframe'))

const WikipediaLazy = React.lazy(() => import('./Wikipedia'))
const WikipediaResultsIframeLazy = React.lazy(() => import('./Wikipedia/WikipediaResultsIframe'))

const StickyLazy = React.lazy(() => import('react-sticky-el'))

// const WikipediaResultsIframeLazy = ssr ? () => null : React.lazy(() => import('./Wikipedia/WikipediaResultsIframeLazy'))

/// ///////////////////

// const Translate = loadable(() =>  import('./Translate'))
// const Email = loadable(() =>  import('./Email'))
// const GoogleLazy = loadable(() =>  import('./Google/GoogleLazy'))
// const GoogleResultsIframeLazy = loadable(() =>  import('./Google/GoogleResultsIframeLazy'))
// const WikipediaLazy = loadable(() =>  import('./Wikipedia/WikipediaLazy'))
// const WikipediaResultsIframeLazy = loadable(() =>  import('./Wikipedia/WikipediaResultsIframeLazy'))

// const FreeText = React.lazy(() => import('./FreeText'));

const mobileBreakpoint = '650'
const queryMobile = `(max-width:${mobileBreakpoint}px)`

const verticalAdBreakpoint = '1024'
const queryVerticalAd = `(min-width:${verticalAdBreakpoint}px)`

const StickyOnDemand = ({ disabled, ...props }) => {
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let listener
            if (!scrolled) {
                listener = window.addEventListener('scroll', () => setScrolled(true))
            }
            return () => window.removeEventListener(listener)
        }
    }, [scrolled])

    return scrolled && !disabled ? <StickyLazy {...props} /> : <div className='sticky'>{props.children}</div>
}

const LazyLoadTransliteration = ({ kbLangKey }) => {
    const transliterationState = useContext(TransliterationContext.State)
    const transliterationDispatch = useContext(TransliterationContext.Dispatch)

    return (
        <LazyLoad
            id='TransliterateBox'
            condition={transliterationState.transliteration}
            reactLazyFunc={() => import('../../Components/TransliterateBox')}
            kbLangKey={kbLangKey}
            editableId={'textbox1'}
            transliterationState={transliterationState}
            transliterationDispatch={transliterationDispatch}
        />
    )
}

function Keyboard({ kbLangKey, sectionKey, parsedHash }) {
    const [t, i18n] = useTranslation()
    const [isVerticalAd] = useState(typeof window !== 'undefined' ? window.matchMedia(queryVerticalAd).matches : false)
    const [mobileLoadKeyboard, setMobileLoadKeyboard] = useState(false)
    const native_title = keyboardLanguages.data[kbLangKey].title_native

    const [isMobileMedia, setIsMobileMedia] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsMobileMedia(window.matchMedia(queryMobile).matches)
        }
    }, [])

    useEffect(() => {
        if (isMobileMedia) {
            import('./MobileExtra.scss').then(() => {})
        }
    }, [isMobileMedia])

    // return keyboardExist ? <Exist {...props} t={t} /> : <NotFound {...props} />
    return (
        <div className={keyboardLanguages.data[kbLangKey].rtl ? 'kb-rtl' : ''}>
            <div className='logo-with-bg'>
                <div className='world_pattern_wrap'>
                    <div className='world_pattern' />
                </div>
                <div className='logo-gate2home-world'>
                    <Link
                        className='site_name'
                        to={sitePath.keysToPath('', '', i18n)}
                        // style="text-decoration: none;"
                    >
                        Gate2Home
                        <div className='logo-bubble' alt='Logo Symbol' width='38' height='37' />
                    </Link>
                    <h1>
                        <Link
                            to={sitePath.keysToPath(kbLangKey, '', i18n)}
                            // style="text-decoration: none;"
                        >
                            {keyboardLanguages.keyToLocaleName(kbLangKey, i18n)}
                            {native_title && kbLangKey !== i18n.language ? ' (' + native_title + ')' : ''}
                        </Link>
                    </h1>
                </div>
            </div>

            <div className='below-logo' role='main'>
                <div id='upper-link-units-div'>
                    <div className='hr-longer' />
                    <div className='upper-link-unit-respons'>
                        {/* <!-- upper_link_unit_respons --> */}
                        <AdsenseAdTrackedInternal
                            updateKey={kbLangKey + sectionKey + 'up'}
                            adSlot='8565706451'
                            adFormat='link'
                        />
                    </div>
                    <div className='hr-longer' />
                </div>

                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    <div id='sky-left-div'>
                        {/* <!-- sky-left --> */}
                        <AdsenseAd
                            updateKey={kbLangKey + sectionKey + 'left'}
                            className='sky-left'
                            adSlot='5635065255'
                            adFormat={isVerticalAd ? 'vertical' : 'auto'}
                        />
                    </div>
                    <div className='stickyBoundary2'></div>
                    <div className='layout'>
                        <TransliterationContext.Provider>
                            <div className='actionBarStickyBoundary'>
                                {sectionKey === 'freetext' && (
                                    <StickyOnDemand
                                        mode='top'
                                        style={{ zIndex: 1 }}
                                        // disabled={sectionKey === 'email'}
                                        disabled={isMobileMedia && !mobileLoadKeyboard}
                                        positionRecheckInterval={100}
                                        boundaryElement={'.actionBarStickyBoundary'}
                                    >
                                        <ActionBar />
                                    </StickyOnDemand>
                                )}
                                {keyboardLanguages.data[kbLangKey].transliteration && (
                                    <LazyLoadTransliteration kbLangKey={kbLangKey} />
                                )}

                                {sectionKey === 'freetext' && <FreeText kbLangKey={kbLangKey} i18n={i18n} />}
                                {sectionKey === 'google' && <GoogleLazy kbLangKey={kbLangKey} i18n={i18n} />}
                                {sectionKey === 'wiki' && <WikipediaLazy kbLangKey={kbLangKey} i18n={i18n} />}
                                {sectionKey === 'trans' && <Translate kbLangKey={kbLangKey} i18n={i18n} />}
                                {sectionKey === 'email' && <EmailLazy kbLangKey={kbLangKey} i18n={i18n} />}
                                {/* { typeof window !== 'undefined' &&  */}
                            </div>
                            <StickyOnDemand
                                mode='bottom'
                                style={{ opacity: '0.96' }}
                                disabled={isMobileMedia && !mobileLoadKeyboard}
                                positionRecheckInterval={100}
                                boundaryElement={'.stickyBoundary2'}
                            >
                                <div>
                                    {isMobileMedia && !mobileLoadKeyboard && (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '116px',
                                                backgroundColor: '#abb4be',
                                                color: 'white',
                                                paddingTop: '70px'
                                            }}
                                        >
                                            <Button
                                                color='primary'
                                                variant='contained'
                                                onClick={() => setMobileLoadKeyboard(true)}
                                            >
                                                {t('UI:Load Keyboard')}
                                            </Button>
                                        </div>
                                    )}
                                    <Suspense fallback={<Loader />}>
                                        {(!isMobileMedia || mobileLoadKeyboard) && (
                                            <VirtualKeyboardLazy
                                                layout2={keyboardLanguages.data[kbLangKey].layout}
                                                kbLangKey={kbLangKey}
                                                sectionKey={sectionKey}
                                                mobileLoadKeyboard={mobileLoadKeyboard}
                                                t={t}
                                            />
                                        )}
                                    </Suspense>
                                </div>
                            </StickyOnDemand>

                            {/* } */}
                        </TransliterationContext.Provider>
                        <Suspense fallback={<Loader />}>
                            <>
                                {sectionKey === 'google' && (
                                    <GoogleResultsIframeLazy q={parsedHash.q} kbLangKey={kbLangKey} />
                                )}
                                {sectionKey === 'wiki' && (
                                    <WikipediaResultsIframeLazy q={parsedHash.q} kbLangKey={kbLangKey} />
                                )}
                            </>
                        </Suspense>

                        <div className='before_bottom_rect'>
                            {/* <!-- bottom_rect336x228 --> */}
                            <AdsenseAd
                                updateKey={kbLangKey + sectionKey + 'botrec'}
                                adSlot='7477043656'
                                style={{ display: 'inline-block', width: 336 + 'px', height: 280 + 'px' }}
                            />
                        </div>
                    </div>
                    <div id='sky-right-div'>
                        {/* <!-- sky-right --> */}
                        <AdsenseAd
                            updateKey={kbLangKey + sectionKey + 'right'}
                            adSlot='2402397259'
                            className='sky-right'
                            style={{ display: 'inline-block' }}
                            adFormat={isVerticalAd ? 'vertical' : 'auto'}
                        />
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default Keyboard
