import React from 'react'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'react-router'
// import Helmet from "react-helmet";
import Locales from '../StaticData/Locales'
import { keyboardLanguages, sitePath } from '../StaticData/SiteData.js'

function HeadMeta({ t, i18n, history, staticContext = {}, resolvedParts }) {
    // function HeadMeta( { kbLangKey,sectionKey, i18n}) {

    const { kbLangKey, sectionKey } = resolvedParts
    staticContext.resolvedParts = resolvedParts

    const native_title = kbLangKey && keyboardLanguages.data[kbLangKey].title_native

    const seoMeta = {
        title: kbLangKey
            ? i18n.t(`SEO:(title)(keyboard)`, {
                  // Virtual keyboard in {{Lang}} &trade; {{extra}}
                  Lang: keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n),
                  extra:
                      (native_title && kbLangKey !== i18n.language ? ' (' + native_title + ')' : '') +
                      (sectionKey !== 'freetext' ? ' - ' + i18n.t(`SEO:(title)(section)${sectionKey}`) : '')
              })
            : i18n.t('SEO:(title)(home)'),
        keywords: i18n.t(`SEO:(keywords)(${sectionKey})`, {
            LangLoc: kbLangKey && keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n)
        }),
        description: i18n.t(`SEO:(description)(${sectionKey})`, {
            LangFull: kbLangKey && keyboardLanguages.keyToLocaleNamePlusNative(kbLangKey, i18n, false)
        }),
        image:
            '//us-central1-g2h-image-maker.cloudfunctions.net/ogimage/api/ogimage?text=' +
            (native_title || (kbLangKey && keyboardLanguages.keyToLocaleName(kbLangKey, i18n)) || 'Online Keyboard')
                .replace(' ', '_')
                .replace('%20', '_') // fb fails to retreive when there are spaces
    }

    return (
        // <HelmetProvider>

        <Helmet>
            <title>{seoMeta.title}</title>
            <link rel='canonical' href={`https://gate2home.com${sitePath.keysToPath(kbLangKey, sectionKey, i18n)}`} />
            {/* <link rel="canonical" href={`${window.location.protocol}//${window.location.hostname}${sitePath.keysToPath(kbLangKey,sectionKey)}`} /> */}

            {/* {navigator.userAgent === 'ReactSnap' && */}
            {Locales.data.map(locale => (
                <link
                    rel='alternate'
                    key={`hrefLang-${locale}-${kbLangKey}-${sectionKey}`}
                    href={sitePath.keysToPath(kbLangKey, sectionKey, i18n, locale)}
                    hrefLang={locale}
                />
            ))}

            <meta name='keywords' content={seoMeta.keywords} />
            <meta name='description' content={seoMeta.description} />
            <meta property='fb:app_id' content='135016923220292' />
            <meta property='og:title' content={seoMeta.title} />
            <meta property='og:description' content={seoMeta.description} />
            <meta property='og:site_name' content='Gate2Home.com - Online Virtual Text Keyboard' />
            <meta
                property='og:url'
                content={`https://gate2home.com${sitePath.keysToPath(kbLangKey, sectionKey, i18n)}`}
            />
            <meta property='og:type' content='website' />
            <meta property='og:image:width' content='1024' />
            <meta property='og:image:height' content='1024' />
            <meta property='og:image:type' content='image/png' />
            <meta property='og:image:url' content={'https:' + seoMeta.image} />
            {/* <meta property='og:image:secure_url' content={'https:' + seoMeta.image} /> */}
            <meta name='twitter:title' content={seoMeta.title} />
            <meta name='twitter:description' content={seoMeta.description} />
            <meta name='twitter:image' content={'https:' + seoMeta.image} />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:site' content='@Gate2HomeCom' />
        </Helmet>
    )
}

export default withRouter(HeadMeta)
