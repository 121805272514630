import React, { useCallback, useReducer } from 'react'
import { withLog } from './withLog'

const initialState = {
    // transliteration:
    transliteration: false,
    stash: false,
    showEnglishKeyboard: false,
    rtl: false,
    returnPosition: { top: 0 },
    textToCheck: '',
    // textToInsert: '',
    chosenIndex: 0,
    results: [],
    done: false
}

const transliterationSetChoice = (state, index) => ({
    ...state,
    chosenIndex: Math.min(Math.max(index, 0), state.results.length - 1)
})

const reducer = (state, action) => {
    switch (action.type) {
        case 'activate-tansliteration':
            return action.payload ? { ...state, transliteration: true } : { ...initialState, rtl: state.rtl }
        case 'set-rtl':
            return { ...state, rtl: action.payload }
        case 'update-return-position':
            return { ...state, returnPosition: action.payload }
        case 'add-char':
            return { ...state, textToCheck: state.textToCheck + action.payload }
        case 'clear':
            return { ...state, textToCheck: '', done: false, chosenIndex: 0 }
        case 'backspace':
            return { ...state, textToCheck: state.textToCheck.slice(0, -1) }
        case 'set-results':
            return { ...state, results: action.payload }
        case 'set-choice':
            return transliterationSetChoice(state, action.payload)
        case 'choice-left':
            return transliterationSetChoice(state, state.chosenIndex + (state.rtl * 2 - 1)) // if rtl 1 else -1
        case 'choice-right':
            return transliterationSetChoice(state, state.chosenIndex - (state.rtl * 2 - 1))
        // case 'insert-text':
        //     return { ...state, textToInsert: action.payload }
        case 'done':
            return { ...state, done: true }
        case 'stash':
            return !state.stash ? { ...state, stash: state.transliteration, transliteration: false } : state
        case 'unstash':
            return state.stash ? { ...state, stash: false, transliteration: state.stash } : state
        // case 'english-keyboard':
        //          return action.payload === state.showEnglishKeyboard ? state : {...state, showEnglishKeyboard: action.payload }
        default:
            return state
    }
}

// const Context = React.createContext()

// const Provider = ({ children }) => {
//     const memoizedReducer = useCallback(withLog(reducer, 'Transliteration'), [reducer])
//     let [state, dispatch] = useReducer(memoizedReducer, initialState)
//     let value = { state, dispatch }

//     return <Context.Provider value={value}>{children}</Context.Provider>
// }

// export default { Context, Provider }

const State = React.createContext()
State.displayName = 'TransliterationState'
const Dispatch = React.createContext()
Dispatch.displayName = 'TransliterationDispatch'

const combinedReducer = withLog(reducer, 'Transliteration')

const Provider = ({ children }) => {
    const memoizedReducer = useCallback(combinedReducer, [])
    const [state, dispatch] = useReducer(memoizedReducer, initialState)

    return (
        <Dispatch.Provider value={dispatch}>
            <State.Provider value={state}>{children}</State.Provider>
        </Dispatch.Provider>
    )
}

export default { State, Dispatch, Provider }
