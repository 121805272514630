import { Button } from '@material-ui/core'
import upperFirst from 'lodash.upperfirst'
import React, { useContext } from 'react'
// import { useTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import { AdsenseAdTrackedInternal } from '../Components/AdsenseAd'
import Gate2HomeContext from '../Context/Gate2HomeContext'
import flag_de from '../Images/flags/flags-iso/flat/24/DE.png'
import flag_es from '../Images/flags/flags-iso/flat/24/ES.png'
import flag_fr from '../Images/flags/flags-iso/flat/24/FR.png'
import flag_en from '../Images/flags/flags-iso/flat/24/GB.png'
// import Locales from '../StaticData/Locales';
// var classes = new BEMHelper('Footer')
// import Flag from 'react-flags'
import flag_il from '../Images/flags/flags-iso/flat/24/IL.png'
import flag_sa from '../Images/flags/flags-iso/flat/24/SA.png'
import flag_ru from '../Images/flags/flags-iso/flat/24/RU.png'

import flag_kr from '../Images/flags/flags-iso/flat/24/KR.png'
import flag_in from '../Images/flags/flags-iso/flat/24/IN.png'
import flag_ir from '../Images/flags/flags-iso/flat/24/IR.png'
import flag_pk from '../Images/flags/flags-iso/flat/24/PK.png'

import { keyboardLanguages, locales, sitePath } from '../StaticData/SiteData.js'
import '../Styles/shared.scss'
// import React, { Component, Suspense, useContext, useEffect, useState } from 'react'
// import BEMHelper from 'react-bem-helper'
import './Footer.scss'

// const AdsenseAdLazy = React.lazy(() => import('../Components/AdsenseAd'))

export const flags = {
    he: flag_il,
    fr: flag_fr,
    en: flag_en,
    es: flag_es,
    de: flag_de,
    ar: flag_sa,
    ru: flag_ru,
    fa: flag_ir,
    ko: flag_kr,
    ur: flag_pk,
    hi: flag_in
}

const Footer = React.memo(({ kbLangKey, changeLanguage }) => {
    const [t, i18n] = useTranslation()

    const Gate2HomeState = useContext(Gate2HomeContext.State)

    // const footer_lang_links = keyboardLanguages.allSortedLocaleNamePlusNative(i18n).map(obj => (
    //     <Link key={obj.key} to={sitePath.keysToPath(obj.key, '', i18n)}>
    //         {obj.value}
    //     </Link>
    // ))
    // const footer_lang_chunks = [] //  lighthouse requirement: 'Have a parent node with more than 60 child nodes.'
    // for (let i = 0; i < footer_lang_links.length; i = i + 59) {
    //     footer_lang_chunks.push(footer_lang_links.slice(i, i + 59))
    // }
    return (
        <footer className='Footer'>
            <div className='fullwidth'>
                {/* sections */}
                <>
                    {Gate2HomeState.sectionKey !== 'home' && (
                        <>
                            <div className='selectlang footer-sections'>
                                <span className='label'>
                                    {Gate2HomeState.kbLangKey &&
                                        upperFirst(
                                            t('UI:(section){{KeyboardName}} Sections', {
                                                KeyboardName: keyboardLanguages.keyToLocaleName(
                                                    Gate2HomeState.kbLangKey,
                                                    i18n
                                                )
                                            })
                                        ) + ':'}
                                </span>
                                <NavLink
                                    key='1'
                                    className='Button NavLink Section'
                                    to={sitePath.keysToPath(
                                        Gate2HomeState.kbLangKey,
                                        'freetext',
                                        i18n,
                                        Gate2HomeState.locale
                                    )}
                                    isActive={() => Gate2HomeState.sectionKey === 'freetext'}
                                    activeClassName='selected'
                                >
                                    {t('UI:(section)Keyboard')}
                                </NavLink>

                                {/* search */}
                                {Gate2HomeState.kbLangKey && (
                                    <>
                                        <NavLink
                                            to={sitePath.keysToPath(Gate2HomeState.kbLangKey, 'google', i18n)}
                                            activeClassName='selected'
                                            className='Button NavLink DropMenuItem'
                                        >
                                            {t('UI:(section){{Lang}} Google', {
                                                Lang: ''
                                            })}
                                            {/* Todo: 'Arabic Google'. translate pattern '{langname} Google'  */}
                                        </NavLink>

                                        <NavLink
                                            to={sitePath.keysToPath(Gate2HomeState.kbLangKey, 'wiki', i18n)}
                                            activeClassName='selected'
                                            className='Button NavLink'
                                        >
                                            {t('UI:(section){{Lang}} Wikipedia', {
                                                Lang: ''
                                            })}
                                        </NavLink>
                                    </>
                                )}
                                {/* end search */}

                                <NavLink
                                    key='3'
                                    className='Button NavLink Section'
                                    to={sitePath.keysToPath(
                                        Gate2HomeState.kbLangKey,
                                        'email',
                                        i18n,
                                        Gate2HomeState.locale
                                    )}
                                    activeClassName='selected'
                                >
                                    {t('UI:(section)Send Email')}
                                </NavLink>
                                <NavLink
                                    key='4'
                                    className='Button NavLink Section'
                                    to={sitePath.keysToPath(
                                        Gate2HomeState.kbLangKey,
                                        'trans',
                                        i18n,
                                        Gate2HomeState.locale
                                    )}
                                    activeClassName='selected'
                                >
                                    {t('UI:(section)Translation')}
                                </NavLink>
                            </div>

                            {/* <div className='hr-short' /> */}
                        </>
                    )}
                    {/* end sections */}
                </>

                {/* <div className='selectlang footer-links'>
                    {footer_lang_chunks.map(chunk => (
                        <span>{chunk}</span>
                    ))}
                </div> */}
                {/* <div className='hr-short' /> */}
                {/* <div id='footer-link-units-div'> */}
                <div className='footer-link-unit-respons'>
                    {/* <!-- starter-bottom-links --> */}
                    {/* TODO: had className="hidden-phone" */}
                    {/* {!kbLangKey && ( */}
                    <AdsenseAdTrackedInternal
                        adSlot='9883681401'
                        updateKey={
                            'home-footer-unit' // if 'home' section.
                        }
                        adFormat='link'
                    />
                    {/* )} */}
                </div>
                {/* <div className='hr-short' /> */}
                {/* </div> */}
                <div className='changeLanguage'>
                    {t('UI:Site language')}: &nbsp;
                    <span style={{ display: 'inline-block' }}>
                        {locales.data.map(locale => (
                            <Button
                                key={`flag-span-${locale}`}
                                id={`flag-span-${locale}`}
                                onClick={() => changeLanguage(locale)}
                                title={keyboardLanguages.data[locale].name_native}
                                style={{ margin: 0 }}
                            >
                                <img alt={keyboardLanguages.data[locale].name_native} src={flags[locale]} />
                                {/* <Flag 
                      name={locales.flag[locale]} 
                      format="png"
                      pngSize={24}
                      shiny={false}
                      basePath='/flags'
                      svg/> */}
                            </Button>
                        ))}
                    </span>
                </div>

                <div className='copyright'>
                    <Link to={sitePath.keysToPath('', '', i18n)}>
                        &copy; Gate2Home.com - {t('BRAND:Type in your language, anywhere')}.
                    </Link>

                    {!Gate2HomeState.kbLangKey && (
                        <a
                            className='debugger_ru'
                            href='http://debugger.ru/projects/virtualkeyboard'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {' '}
                            (&copy; VirtualKeyboard 3.7)
                        </a>
                    )}
                </div>
            </div>
        </footer>
    )
})

export default Footer
