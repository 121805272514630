import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'

const share_host_prefix = 'https://gate2home.com'

export const addthisLoad = () =>
    import('scriptjs').then(scriptjs => scriptjs.default('//s7.addthis.com/js/300/addthis_widget.js#username=ilanbm'))

export const AddThisToolbox = React.memo(({ url }) => {
    /* eslint-disable */

    useEffect(() => {
        if (typeof window !== 'undefined' && window.addthis) {
            window.addthis.update('share', 'url', share_host_prefix + url)
            window.addthis.url = share_host_prefix + url
            window.addthis.toolbox('.addthis_toolbox')
            window.addthis.init()
        }
    }, [url])

    return (
        <div className='addthis_toolbox addthis_default_style addthis_32x32_style'>
            <a className='addthis_button_preferred_1' />
            <a className='addthis_button_preferred_2' />
            <a className='addthis_button_preferred_3' />
            <a className='addthis_button_preferred_4' />
            <a className='addthis_button_compact' />
            <a className='addthis_counter addthis_bubble_style' />
        </div>
    )

    /* eslint-enable */
})

export const AddThisShareMenuButton = React.memo(({ children }) => {
    /* eslint-disable */

    return (
        <Button className='Button Share' onClick={addthisLoad} onMouseOver={addthisLoad}>
            <a href='#' className='addthis_button_expanded'>
                <span
                    className='at-icon-wrapper'
                    style={{
                        backgroundColor: 'rgb(255, 101, 80)',
                        lineHeight: '16px',
                        height: '16px',
                        width: '16px'
                    }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 32 32'
                        version='1.1'
                        role='img'
                        aria-labelledby='at-svg-addthis-1'
                        title='Expanded'
                        alt='Expanded'
                        className='at-icon at-icon-addthis'
                        style={{ width: '16px', height: '16px' }}
                    >
                        <g>
                            <path d='M18 14V8h-4v6H8v4h6v6h4v-6h6v-4h-6z' fillRule='evenodd' />
                        </g>
                    </svg>
                </span>
                {children}
            </a>
        </Button>
    )

    /* eslint-enable */
})
