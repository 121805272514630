import React, { useEffect, Suspense, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from '@material-ui/core'
import Loader from './Loader'
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(0.5),
        direction: 'ltr',
        textTransform: ({ textTransform }) => textTransform,
        padding: '6px 9px 6px 12px',
        fontWeight: 'bold',
        color: ({ color }) => color || undefined,
        backgroundColor: ({ bgColor }) => bgColor + ' !important',
        '&:hover': {
            filter: 'brightness(122%)'
        },
        wordWrap: 'normal'
    }
}))

const StyledMenu = React.lazy(() => import('./StyledMenu'))

const DropButtonMenu = React.memo(
    ({
        children,
        resetKey,
        generateOptionsDynamically,
        text,
        Icon,
        onClick,
        eventKey,
        variant = 'contained',
        style,
        className,
        which,
        onItemClick,
        menuItemProps,
        ...props
    }) => {
        const classes = useStyles(props)

        const [anchorEl, setAnchorEl] = React.useState(null)
        const [options, setOptions] = React.useState([])

        const handleClick = useCallback(
            event => {
                // window.dataLayer.push({
                //     event: 'button_click_event', // Not necessarily needed if this loads before GTM is initialized.
                //     button_name_var: 'button: ' + eventKey
                // })
                setOptions(generateOptionsDynamically ? [children, ...generateOptionsDynamically()] : children)
                if (onClick) {
                    onClick(event)
                } else {
                    setAnchorEl(event.currentTarget)
                }
            },
            [children, generateOptionsDynamically, onClick]
        )

        const handleClose = useCallback(() => {
            setAnchorEl(null)
        }, [])

        useEffect(() => {
            setAnchorEl(null)
        }, [resetKey])

        return (
            <>
                <Button
                    style={{ textTransform: props.textTransform, ...style }}
                    className={className || classes.button}
                    variant={variant}
                    color='inherit'
                    onClick={handleClick}
                    startIcon={Icon ? <Icon /> : undefined}
                    endIcon={(children || which) && <ArrowDropDownIcon />}
                >
                    {text}
                </Button>
                {(children || which) && anchorEl ? (
                    <Suspense fallback={<Loader style={{ top: 0 }} />}>
                        <StyledMenu
                            id={'customized-menu-' + text.toLowerCase()}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            which={which}
                            onItemClick={onItemClick}
                            menuItemProps={menuItemProps}
                        >
                            {options}
                        </StyledMenu>
                    </Suspense>
                ) : null}
            </>
        )
    }
)

export default DropButtonMenu
